import './App.css';
import { useEffect } from 'react';
import Navbar from './Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import AppsPage from './AppsPage';
import AboutPage from './AboutPage';
import ContactPage from './ContactPage';

const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

function App() {
  useDocumentTitle('Neural Mystic Labs');

  return (
      <Router>
        <Navbar />
        <div className='background'>
        <div className='pageContent'>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Apps" element={<AppsPage />} />
          <Route path="/About" element={<AboutPage />} />
          <Route path="/Contact" element={<ContactPage />} />
        </Routes>
      </div>
      </div>
    </Router>
  );
}

export default App;
