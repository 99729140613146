// AboutPage.js
import React from 'react';

function AboutPage() {
  return (
    <div>
      <h1>Who we are</h1>
      <p>At Neural Mystic Labs, we specialize in crafting intelligent applications that leverage cutting-edge AI to empower businesses and individuals. Our apps are designed to simplify complex tasks, enhance productivity, and provide intuitive solutions tailored to your unique needs. By integrating advanced machine learning algorithms, we help our customers harness the full potential of artificial intelligence, transforming how they interact with technology. Whether you're automating workflows or seeking innovative AI-driven insights, we're here to help you succeed in the digital age.</p>

<h1>What We Do</h1>

<p>Our cutting-edge applications leverage the latest advancements in AI to automate tasks, streamline workflows, and enhance decision-making processes. Whether you’re a small business looking to optimize operations or an individual seeking assistance in daily tasks, our products are designed with your needs in mind.
<ul>
    <li><b>For Businesses:</b> Our AI-driven tools help organizations increase productivity, reduce costs, and gain valuable insights from data. From task automation to predictive analytics, we provide the resources you need to stay competitive in a fast-paced world.</li>

    <li><b>For Individuals:</b> We understand that managing personal tasks can be overwhelming. Our user-friendly apps assist with everything from scheduling and reminders to personalized recommendations, making your life easier and more organized.</li>
    </ul>
    </p>

    <h1>Our Vision</h1>

<p>We envision a future where AI is seamlessly integrated into everyday tasks, freeing up time and resources for what truly matters. We strive to create applications that not only enhance efficiency but also foster creativity and innovation.
Our Commitment to Innovation</p>

<p>Innovation is at the heart of everything we do. We continuously invest in research and development to stay ahead of industry trends and deliver the most effective solutions to our users. Our agile approach allows us to adapt and evolve our products based on user feedback and technological advancements.</p>
<h1>Join Us on Our Journey</h1>

<p>We invite you to explore our suite of applications and discover how we can help you transform the way you work and live. Whether you are a business leader, a busy professional, or anyone in between, Neural Mystic Labs is here to support your journey toward greater efficiency and success.</p>

<h1>Contact Us</h1>

<p>Have questions or want to learn more? Feel free to reach out to us at [contact email] or follow us on [social media links].</p>
    </div>
  );
}

export default AboutPage;
