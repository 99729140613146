// ContactPage.js
import React from 'react';

function ContactPage() {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>We would love to hear from you!</p>
    </div>
  );
}

export default ContactPage;
