// HomePage.js
import React from 'react';

function HomePage() {
  return (
    <div class="main-flex-container">
    <div class="hero-text-container">
    <h1 class="headline">Innovating Tomorrow with AI Today</h1>
    <p class="subheadline">Empowering Innovation with AI-Driven Solutions</p>
    </div>
  </div>
    
  );
}

export default HomePage;
