import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Navbar() {

    function toggleDropdown() {
        var x = document.getElementById("nml_navbar");
        if (x.className === "navbar-right") {
             x.className += " responsive";
        } else {
             x.className = "navbar-right";
        }
    } 

    // used to close the dropdown on clicking a link
    function closeDropdown() {
        var x = document.getElementById("nml_navbar");
        if (x.className === "navbar-right responsive") {
            x.className = "navbar-right";
        }
    }

    const location = useLocation();

  return (
    // We could get a list of top level locations and generate the links in a loop
    <nav>
      <div className="navbar">
               <Link to="/" onClick={closeDropdown} className='nmlname'>Neural Mystic Labs</Link>

               <div className="navbar-right" id="nml_navbar">
                <Link to="/Apps" onClick={closeDropdown} className={location.pathname === '/Apps' ? 'active' : ''}> APPS </Link>
                <Link to="/About" onClick={closeDropdown} className={location.pathname === '/About' ? 'active' : ''}> ABOUT </Link>
                <Link to="/Contact" onClick={closeDropdown} className={location.pathname === '/Contact' ? 'active' : ''}> CONTACT </Link>
                    <a className="icon" onClick={toggleDropdown}>
                         <i className="fa fa-bars"></i>
                    </a>
               </div>  
          </div>
    </nav>
  );
}

export default Navbar;
